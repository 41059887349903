.rotateR {
  animation: rotateR 0.5s linear 0s;
}

@keyframes rotateR {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
